












const FullscriptLogo = () => (
    <svg width={100} height={100} viewBox="0 0 156.23 107.1" fill="none" role="img" aria-label="Fullscript logo">
      <title>Fullscript logo</title>
      <desc>The Fullscript green leaf logo alongside the text Fullscript</desc>
      <defs>
        <style>{".cls-1{fill:#88b04b;}.cls-2{fill:#404f5e;}"}</style>
      </defs>
      <path
        d="M98.982 1.67584C98.0628 0.75666 96.8295 0.186537 95.5264 0.105091C75.3045 -1.13987 62.2148 9.08743 55.2687 18.6981C48.2992 9.08743 35.2214 -1.15151 14.9878 0.105091C13.6731 0.186537 12.4397 0.75666 11.5206 1.67584C-1.46426 14.6723 -0.0447754 35.1384 0.129752 37.1397C0.211198 38.5943 1.04893 48.6819 7.65769 60.2589C13.9872 71.3472 27.0535 86.1005 53.4187 93.5005C53.5467 93.547 53.6979 93.582 53.8376 93.6286C53.9423 93.6633 54.0353 93.6751 54.1403 93.6983C54.2681 93.7332 54.4077 93.7448 54.5474 93.7682C54.7801 93.8148 55.0244 93.8147 55.2571 93.8147C55.4899 93.8147 55.7108 93.8148 55.932 93.7682C56.06 93.7448 56.1763 93.7217 56.3043 93.7098C56.4206 93.6867 56.5252 93.6633 56.6417 93.6286C56.793 93.582 56.9442 93.547 57.1072 93.5005C105.835 79.7942 110.059 40.9096 110.35 37.2678C110.478 36.081 112.258 14.9748 98.9704 1.68747L98.982 1.67584ZM49.905 46.925L49.8584 46.8784L35.4308 31.3339C33.4179 29.158 30.0089 29.0302 27.8448 31.043C25.6689 33.0675 25.5408 36.4649 27.5539 38.629L49.8584 62.6673L49.905 62.7138V81.1207C49.905 81.1207 49.87 81.1207 49.8584 81.1207C12.9168 67.6471 10.9156 37.8264 10.8574 36.5115C10.8574 36.4184 10.8341 36.3137 10.8341 36.2206C10.8108 36.0578 9.42624 20.6993 17.757 10.7396C17.9781 10.7396 18.1875 10.7396 18.4086 10.7396C39.6311 10.7396 48.0667 26.7728 49.8584 30.8103C49.8584 30.8453 49.8934 30.8684 49.905 30.9034V46.925ZM99.6802 36.2322C99.6802 36.3137 99.6568 36.4184 99.6568 36.4999C99.5871 37.8379 97.5858 67.6471 60.6443 81.0857C60.6209 81.0857 60.6093 81.0857 60.5978 81.1091V62.7138L60.6443 62.6673L82.9488 38.629C84.9616 36.4649 84.8336 33.0675 82.6579 31.043C80.482 29.0302 77.0846 29.158 75.0601 31.3339L60.6443 46.8784L60.5978 46.925V30.9034C60.5978 30.9034 60.6325 30.8334 60.6443 30.7869C62.4244 26.8077 70.9763 10.7396 92.1173 10.7396C92.3267 10.7396 92.5245 10.7396 92.734 10.7396C101.007 20.6411 99.6802 36.0694 99.6686 36.2206L99.6802 36.2322Z"
        fill="#88B04B"
      ></path>
     
      {/* Remaining paths truncated for brevity */}
    </svg>
  );
  
  export default FullscriptLogo;
  